import {  makeAutoObservable, runInAction } from 'mobx';
import { setApiToken, apiClient } from '../api'
import * as browserCookies from 'browser-cookies'
// const localStorageTokenKey = '__bubl_token__'
export class AuthStore {
  constructor () {

    makeAutoObservable(this)
    // let tokenData = localStorage.getItem(localStorageTokenKey)
    let host = window.location.host
    if (process.env.NODE_ENV ==='development' || host.indexOf('local-dev.') !== -1) {
      this.isUnderDevelopment = true
      let bubl = browserCookies.get('user-bubl')
      if (bubl) {
        this.uuid = bubl
        window.APP_CONFIG.bublDomain  = browserCookies.get('user-bubl-domain')
      }
    } else if(process.env.REACT_APP_IS_BUBL_FRONTEND) {
      // const uuid5Regex = /^(.*\.)?([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})\.(.*)/
      // let matches = host.match(uuid5Regex)
      // if (matches && matches.length) {
      //   this.uuid = matches[matches.length - 2]
      //   window.APP_CONFIG.bublCloud = matches[matches.length - 1]
      // }
      window.APP_CONFIG.bublDomain = window.location.host  
      this.isUnderBubl = true
    }

    if(this.uuid) {
      window.userBubl = this.uuid
    }
  }

  
  isUnderBubl = false

  
  isUnderDevelopment = false

  
  uuid = null

  
  token = null

  
  isAuthenticated = false

  setAuthenticated(isAuthenticated) {
      this.isAuthenticated = isAuthenticated
  }

  preLogin(skipPreCheck = false) {
    return new Promise((resolve, reject) => {
      if (!skipPreCheck && this.isAuthenticated) {
        return reject('Already authenticated')
      }
      // if (!!this.uuid) {
      //   return reject('TokenNotFound')
      // }

      // this.token = browserCookies.get(`bublauth-${this.uuid}`)
      // setApiToken(this.token)

      apiClient('co-nect/v1/auth', {
        method: 'POST'
      })
        .then(({ data }) => {
          setApiToken(data.accessToken)
          runInAction(() => {
            this.isAuthenticated = true
          })
          resolve('LoggedIn')
        })
        .catch(err => {
          setApiToken('')
          reject('api failed')
        })
    })
  }

  // 
  // setUser ({ token }) {
  //   browserCookies.set(`bublauth-${this.uuid}`, token, {
  //     path: '/',
  //     expires: new Date(Date.now() + 7 * 24 * 3600 * 1000)
  //   })
  //   if (this.isUnderBubl || this.isUnderDevelopment) {
  //     this.token = token
  //     setApiToken(this.token)
  //     this.isAuthenticated = true
  //   }
  //   return false;
  // }

  clearUser = () => {
    browserCookies.set('user-bubl','', {
      path: '/',
      expires: new Date(),
      domain: `.${window.location.host}`
    })
    browserCookies.set('user-bubl-domain','', {
      path: '/',
      expires: new Date(),
      domain: `.${window.location.host}`
    })
    browserCookies.set('bubl-access','', {
      path: '/',
      expires: new Date(),
      domain: `.${window.location.host}`
    })

    runInAction(() => {
      this.isAuthenticated = false
      this.token = null
      this.uuid = null
    })
    setApiToken(null)
    window.userBubl = null
    window.location.assign('/')
    // localStorage.removeItem(localStorageTokenKey)
  };
}
