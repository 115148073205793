import React from 'react';
import {StoreContext} from '../stores/storeContexts'
export function withStore(Component) {
	return function storeComponent(props) {
		return (
			<StoreContext.Consumer>
				{store => <Component {...props} store={store} />}
			</StoreContext.Consumer>
		)
	}
}
