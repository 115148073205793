import { apiClient, setApiToken } from './api-client';

class ApiError extends Error {
    constructor(response, data, message) {
        super(message)
        this.response = response;
        this.data = data
    }
}
export { apiClient, setApiToken, ApiError };
