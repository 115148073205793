import React, { useState } from 'react'
import { observer } from 'mobx-react' // 6.x or mobx-react-lite@1.4.0
import {AuthStore} from './AuthStore'
import {ApplicationStore} from './ApplicationStore'
import { AuthAppStore } from './AuthApp'

function createStore() {
    return {
        auth: new AuthStore(),
        application: new ApplicationStore(),
        authApp: new AuthAppStore()
    }
}
export const StoreContext = React.createContext(null)

export const StoreProvider = observer(({ children }) => {
    const [store] = useState(()=>createStore())
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
})
