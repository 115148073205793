import 'core-js/es/promise';
import 'core-js/es/set';
import 'core-js/es/map';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
// import 'mobx-react-lite/batchingForReactDom'
import App from './App';
import * as serviceWorker from './serviceWorker';
require('./index.css');

function bootstrap() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

// if (process.env.NODE_ENV === 'development') {
  // const { worker } = require('./mocks/browser')
  // worker.start()
// }
function loadConfig(cb) {
  var scriptElm = document.createElement('script');
  scriptElm.id = 'config-script';
  scriptElm.src = `${
    process.env.PUBLIC_URL
  }/config.js?t=${new Date().getTime()}`;
  scriptElm.onload = cb;
  document.body.appendChild(scriptElm);
}

loadConfig(bootstrap);
