import React from "react";
import { withFormik } from "formik";
import { string, object, ref } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { apiClient } from "../../api";
import { withStore } from "../../hoc/withStore";
import { useTranslation } from "react-i18next";

function SetPasswordForm(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    status,
    isSubmitting,
  } = props;
  const {t} = useTranslation()
  return (
    <div id="auth-full-container">
      <div id="auth-header">
          {props.authApp?.appInfo?.logo?<img  id="auth-app-logo"alt="app-logo" src={`${props.authApp?.appInfo?.logo?.indexOf('data:image')===-1?'data:image/jpeg;base64,':''}${props.authApp?.appInfo?.logo}`} />:null}
          <h2 id="auth-header-heading">{props.authApp?.appInfo?.name||""}</h2>
        </div>
      <form
        data-testid="set-password-form"
        id="auth-app-form"
        onSubmit={handleSubmit}
      >
        <div className="auth-form-row">
          <label
            className="auth-app-input-label"
            htmlFor="email"
          >
            {t("Email")}
          </label>
          <input
            className="auth-app-input-field"
            id="email"
            name="email"
            type="text"
            placeholder={t("Email")}
            disabled
            value={values.email}
            onBlur={(e)=> {
              handleBlur(e)
            }}
            data-testid="email-input"
          />
          <p
            data-testid="email-error"
            className="auth-app-input-error"
          >
            {errors.email && touched.email ? t(errors.email) : ""}
          </p>
        </div>
        <div className="auth-form-row">
          <label
            className="auth-app-input-label"
            htmlFor="password"
          >
           {t("Password")}
          </label>
          <p className="auth-app-password-hint">*{t("Password should be 12 characters minimum")}</p>
          <input
            className="auth-app-input-field"
            id="password"
            type="password"
            placeholder={t("Password")}
            name="password"
            onChange={handleChange}
            value={values.password}
            data-testid="password-input"
            onBlur={(e)=> {
              handleBlur(e)
            }}
          />
          <p
            data-testid="password-error"
            className="auth-app-input-error"
          >
            {errors.password && touched.password ? t(errors.password) : ""}
          </p>
        </div>
        <div className="auth-form-row">
          <label
            className="auth-app-input-label"
            htmlFor="confirmPassword"
          >
            {t("Confirm Password")}
          </label>
          <input
            className="auth-app-input-field"
            id="confirmPassword"
            type="password"
            placeholder={t("Confirm Password")}
            name="confirmPassword"
            onChange={handleChange}
            value={values.confirmPassword}
            data-testid="confirmPassword-input"
            onBlur={(e)=> {
              handleBlur(e)
            }}
          />
          <p
              data-testid="confirm-password-error"
              className="auth-app-input-error"
            >
              {errors.confirmPassword ? t(errors.confirmPassword) : ""}
            </p>
        </div>
        <p
          data-testid="form-error"
          id="auth-form-error"
        >
          {status === "SERVER_ERROR"
            ? t("Oops something went wrong, please try again after some time!")
            : ""}
        </p>
        <div id="auth-submit-wrapper">
          <button
            id="auth-app-submit"
            data-testid="setpassword-button"
            aria-label="Submit Sign-Up"
            disabled={!(isSubmitting || (dirty && isValid))}
            className={`${
              !(isValid && dirty) ? "auth-app-submit-disabled" : ""
            } `}
            type="submit"
          >
            {t('Set Password')}
            {isSubmitting ? <FontAwesomeIcon id="auth-app-btn-spinner"  icon={faSpinner} spin /> : null}
          </button>
        </div>
        <p id="auth-app-powered-by" ><span
              style={{
                paddingRight: 4,
                fontSize: 19,
                marginTop: 9,
                fontWeight: 500,
                opacity: 0.4,
              }}
            >
              {t("Powered by")}
            </span>  <img src="/logo-bubl.png" alt="logo" width={120} /></p>
      </form>
    </div>
  );
}

const schema = object().shape({
  email: string()
    .required("Email is required")
    .email("Valid Email is required"),
  password: string()
    .required("Password is required")
    .min(12, "Password should be 12 characters minimum"),
  confirmPassword: string()
    .required("Confirm Password is required")
    .oneOf([ref("password")], "Password does not match"),
});

export const SetPassword3PAppFormWrapper = withStore(
  withFormik({
    mapPropsToValues: (props) => ({
      email: props.data.email,
      password: "",
      confirmPassword: "",
    }),
    validationSchema: schema,
    handleSubmit: (
      values,
      { setSubmitting, props, setFieldError, setStatus, resetForm }
    ) => {
      apiClient("co-nect/v1/setnewpassword", {
        method: "POST",
        body: {
          email: values.email,
          password: values.password,
        },
        headers: {
          Authorization: `Bearer ${props.data.initialToken}`,
        },
      })
        .then((res) => {
          console.log("In close view")
          props.authApp.closeView()
          setStatus("SUCCESS");
        })
        .catch((err) => {
          setStatus("SERVER_ERROR");
          setSubmitting(false);
        });
    },

    displayName: "SignUpForm",
  })(SetPasswordForm)
);
