export default {
	'Please check your email. We have sent you a verification link. Click on email link to verify your account.':
		'Controleer alstublieft uw e-mail. We hebben u een verificatielink gestuurd. Klik op de link in de e-mail om uw account te verifiëren.',
	'Login or Sign Up to start your contact sharing journey':
		'Inloggen of registreren om aan uw contactendelen reis te beginnen',
	'I am new here': 'Ik ben nieuw hier',
	'Already a Member': 'Ik ben al lid',
	'Forgot password?': 'Wachtwoord vergeten?',
	'Change Password': 'Wachtwoord wijzigen',
	'Change Email': 'E-mail wijzigen',
	Dashboard: 'Dashboard',
	Logout: 'Uitloggen',
	'Sign Up': 'Registreren',
	Login: 'Inloggen',
	Email: 'E-mail',
	Password: 'Wachtwoord',
	'Confirm Password': 'Bevestig uw wachtwoord',
	'Set Password': 'Wachtwoord instellen',
	'Email is required': 'E-mail is verplicht',
	'Valid Email is required': 'Geldige e-mail is vereist',
	'Password is required': 'Wachtwoord is verplicht',
	'Password should be 12 characters minimum':
		'Wachtwoord moet minimaal 12 tekens zijn',
	'Confirm Password is required': 'Bevestig wachtwoord is verplicht',
	'Password does not match': 'Wachtwoord komt niet overeen',
	'Powered by': 'Powered by',
	'Data protection':
		'Onze gegevensbescherming wordt gecontroleerd en gecertificeerd volgens de regels van de AVG',
	'Something went wrong, Please try again!':
		'Er is iets fout gegaan, probeer het opnieuw!',
	'Oops something went wrong, please try again after some time!':
		'Oeps, er is iets fout gegaan, probeer het later opnieuw!',
	'Login failed: please check your email':
		'Inloggen mislukt: controleer uw e-mail',
	'Login failed: please check your email and password':
		'Inloggen mislukt: controleer uw e-mail en wachtwoord',
	'Oops something went wrong, please try again in few mins!':
		'Oeps, er is iets fout gegaan, probeer het over een paar minuten opnieuw!',
	ResetPassword:
		'Controleer uw e-mail. We hebben een verificatielink gestuurd naar {{email}}. Click op e-mail link om het wachtwoord opnieuw in te stellen.',
	'Reset password': 'Wachtwoord resetten',
	'Password success':
		'Wachtwoord succesvol veranderd. Ga nu verder met inloggen.',
	Logs: 'Logboeken',
};
