export default {
	'Please check your email. We have sent you a verification link. Click on email link to verify your account.':
		'Please check your email. We have sent you a verification link. Click on email link to verify your account.',
	'Login or Sign Up to start your contact sharing journey':
		'Login or Sign Up to start your contact sharing journey',
	'I am new here': 'I am new here',
	'Already a Member': 'Already a Member',
	'Forgot password?': 'Forgot password?',
	'Change Password': 'Change Password',
	'Change Email': 'Change Email',
	Dashboard: 'Dashboard',
	Logout: 'Logout',
	'Sign Up': 'Sign Up',
	Login: 'Log In',
	Email: 'Email',
	Password: 'Password',
	'Confirm Password': 'Confirm Password',
	'Set Password': 'Set Password',
	'Email is required': 'Email is required',
	'Valid Email is required': 'Valid Email is required',
	'Password is required': 'Password is required',
	'Password should be 12 characters minimum':
		'Password should be 12 characters minimum',
	'Confirm Password is required': 'Confirm Password is required',
	'Password does not match': 'Password does not match',
	'Powered by': 'Powered by',
	'Data protection':
		'Our data protection is checked and certified according to the rules of the GDPR.',
	'Something went wrong, Please try again!':
		'Something went wrong, Please try again!',
	'Oops something went wrong, please try again after some time!':
		'Oops something went wrong, please try again after some time!',
	'Login failed: please check your email':
		'Login failed: please check your email',
	'Login failed: please check your email and password':
		'Login failed: please check your email and password',
	'Oops something went wrong, please try again in few mins!':
		'Oops something went wrong, please try again in few mins!',
	ResetPassword:
		'Please check your email. We have sent a verification link to {{email}}. Click on email link to reset the password.',
	'Reset password': 'Reset password',
	'Password success':
		'Password changed successfully. Please proceed to login now.',
	Logs: 'Logs',
};
