import React, { useLayoutEffect } from "react";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { string, object } from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { apiClient } from "../../api";
import { styleInject } from "../../common/helper";
import { useTranslation } from "react-i18next";

function ForgotPasswordForm(props) {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    status,
    isSubmitting,
  } = props;

  const {t} = useTranslation()

  const themeCss = props.auth?.appInfo?.loginThemeCss
  useLayoutEffect(()=>{
    styleInject(themeCss, {id:'appTheme'})
  },[themeCss])

  const authContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "85vh",
    padding: "2rem",
    maxWidth: "600px"
  };
  
  return status === 'SUCCESS' ? (
    <div id="auth-container" style={authContainerStyle}>
      <div id="auth-header">
        <img
          alt="app-logo"
          className="w-8 h-8"
          src="/images/Logo_247dermatologist.png"
          style={{width: "100%", maxWidth: "400px", height: "auto"}}
        />
      </div>
      <div
        data-testid="success-msg"
        className="w-full mt-6 px-2 lg:px-8 flex-grow flex flex-col justify-center"
        style={{maxWidth: '600px'}}
      >
        <div 
          className="flex flex-col py-4 px-4 text-on-success text-lg font-medium"
          style={{
            backgroundColor: '#ff5f90'
          }}
        >
          <div className="flex flex-row items-center py-4">
              <FontAwesomeIcon icon={faCheckCircle} size={"2x"} />
          </div>
          <div className="flex-grow">
            {t("ResetPassword", { email: values.email})}
          </div>
        </div>
      </div>
    </div>
  ) :(
    <div id="auth-container" style={authContainerStyle}>
      <div id="auth-header">
        <img
          alt="app-logo"
          className="w-8 h-8"
          src="/images/Logo_247dermatologist.png"
          style={{width: "100%", maxWidth: "400px", height: "auto"}}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <input
            className="auth-app-input-field cursor-not-allowed"
            style={{ backgroundColor: "#DCDCDC", fontSize: '1.125rem', color:"#000"}}
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            disabled={true}
            data-testid="email-input"
          />
        </div>
        <div className="mt-10">
          <small id="auth-app-powered-by"
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-start",
                  color:"#28282B"
                }}
          >
              {t("Powered by")} test
            <img src="/logo-bubl.png" alt="logo" width={120} style={{marginLeft: ".5rem"}} />
          </small>
          <small style={{   color:"#28282B"}}>
            {t("Data protection")}
          </small>
        </div>
        <div className="flex flex-col items-center justify-between mt-10">
          <button
              id="auth-app-submit"
              data-testid="login-button"
              aria-label="Submit Login"
              className={`${!values.email ? "auth-app-submit-disabled" : ""} `}
              type="submit"
            >
            {t("Reset password")}
            {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
          </button>
        </div>
      </form>
      <div></div>
    </div>
  );
}

const schema = object().shape({
  email: string()
    .required("Email is required")
    .email("Valid Email is required")
});

export const ForgotPasswordForm3PAppWrapper = withRouter(
  withFormik({
    mapPropsToValues: (props) => {
      return {
        email: props.email ?? ""
      };
    },
    validationSchema: (props) => {
      return schema;
    },
    handleSubmit: (
      values,
      { setSubmitting, setStatus}
    ) => {
      let promise = apiClient(`co-nect/v1/forgotpassword`, {
          method: "POST",
          body: {
            email: values.email
          },
        }).then(({ data }) => {
          setStatus("SUCCESS");
          setSubmitting(false);
        });

      promise.catch(({ data, response }) => {
        setStatus("SERVER_ERROR");
        setSubmitting(false);
      });
    },

    displayName: "ForgotPasswordForm",
  })(ForgotPasswordForm)
);
