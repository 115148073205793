import { observer } from "mobx-react";
import React, { useEffect, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useStores } from "../../hooks/useStore";
import { SetPassword3PAppFormWrapper } from "./SetPassword3PApp";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styleInject } from "../../common/helper";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../shared/LanguageSwitcher";


// https://local-dev.dev.bubl.cloud/auth/new-password/92f2e365-fe04-4657-8993-c02510d29e9b?redirectPath=%2Fservice%2Fv1%2F92f2e365-fe04-4657-8993-c02510d29e9b%2F&email=gurpreetseedhar%2B101%40gmail.com

export const NewPasswordFormWrapper = observer(() => {
  const params = useParams();
  const queryParams = useQueryParams();
  const { authApp } = useStores();
  const {t} = useTranslation()
  useEffect(() => {
    authApp.setCurrentApp(
      params.appId,
      null,
      decodeURI(queryParams.get("redirectPath") ?? "")
    );
    // eslint-disable-next-line
  }, []);
  const themeCss = authApp?.appInfo?.loginThemeCss
  useLayoutEffect(()=>{
    styleInject(themeCss, {id:'appTheme'})
  },[themeCss])
  return (
    <div
      data-testid="email-verify"
      id="auth-page-container"
    >
      <LanguageSwitcher isStatic={true} isTransparent={true} />
      <div id="auth-laoder-wrapper">
        {authApp?.status === "loading" ? (
          <FontAwesomeIcon id="loader-spinner" data-testid="message-icon" icon={faSpinner} spin />
        ) : null}
        {authApp?.status === "loaded" ? (
          <SetPassword3PAppFormWrapper
          authApp={authApp}
          data={{ email: queryParams.get('email'), initialToken: queryParams.get('token') }}
        />
        ) : null}
        {["redirection_failed","failedToLoadApp"].includes(authApp?.status) ? (
          <p id="error-text">ERR: {t(authApp.error)}</p>
        ) : null}
      </div>
    </div>
  );
});
