const { DEFAULT_APP_ICON } = require("../constants")

export function getAppURL(app) {
    if(app.appLogo) {
       if(app.appLogo.startsWith('data')) {
         return app.appLogo
       }
       return `data:image/png;base64,${app.appLogo}`
    }
    return DEFAULT_APP_ICON
}

export function styleInject(css, { id } = {}) {
  if (!css || typeof document === 'undefined') return
  
  let existingStyle  = document.getElementById(id);
  if(existingStyle) {
    existingStyle.parentNode.removeChild(existingStyle);
  }

  const head = document.head || document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.id = id;
  style.type = 'text/css'

  head.appendChild(style)

  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
}