import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useStores } from '../../hooks/useStore'

// import { observer } from 'mobx-react'

export function PrivateRoute ({ component:Component, children, ...rest }) {
  let { auth } = useStores()
  return (
    <Route
      {...rest}
      render={props => {
        return auth.isAuthenticated ? (
            Component ? (
            <Component {...props}/>
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}
