import { runInAction, makeAutoObservable } from 'mobx';
import { apiClient } from '../api';
import { toast } from 'react-toastify';

export class ApplicationStore {
	constructor() {
		makeAutoObservable(this);
	}
	theme = 'light';

	themes = ['light', 'dark'];

	currentMenu = null;

	showSearch = false;

	apps = null;

	loadingApps = false;

	env = {};

	developerTerminalURL = null;

	getApps() {
		this.loadingApps = true;
		apiClient('co-nect/v1/externalapps', {
			method: 'GET',
		})
			.then(({ data }) => {
				runInAction(() => {
					this.apps = data;
					this.loadingApps = false;
				});
			})
			.catch(({ response, data }) => {
				runInAction(() => {
					this.loadingApps = false;
					this.apps = [];
				});
				console.error('application load failed', response);
				// throw new ApiError(response, data, 'application load failed')
			});
	}

	setCurrentMenu(menuName) {
		this.currentMenu = menuName ? menuName : null;
	}

	setSearchState(state) {
		this.showSearch = state;
	}

	setAppStatus(appId, isActive) {
		this.apps.some((app) => {
			if (appId === app.id) {
				app.status = 'ACTIVE';
				return true;
			}
			return false;
		});
	}

	changeTheme(theme) {
		console.log('changing theme');
		document.body.classList.remove(...this.themes);
		if (theme) {
			document.body.classList.add(theme);
			this.theme = theme;
		}
	}

	completeActivation(appId) {
		this.apps.some((app) => {
			if (String(app.id) === String(appId)) {
				app.activating = false;
				app.status = 'ACTIVE';
				return true;
			}
			return false;
		});
	}

	updateApps() {
		apiClient(`co-nect/v1/externalapps/update`, {
			method: 'POST',
			body: {
				updateAll: true,
			},
		})
			.then(() => {
				toast.success('Apps will be updated soon!!', {
					autoClose: true,
					closeButton: true,
				});
			})
			.catch(() => {
				toast.error('Apps update failed, please re-try after sometime', {
					autoClose: true,
					closeButton: true,
				});
			});
	}

	installApp(app) {
		let oldStatus = app.status;
		app.status = 'INSTALLING';
		apiClient(`co-nect/v1/externalapps/install/${app.id}`, {
			method: 'POST',
		})
			.then(({ data, response }) => {
				this.getApps();
			})
			.catch(({ response }) => {
				runInAction(() => {
					app.status = oldStatus;
				});
				console.error('installation error', response);
				toast.error(
					'App installation failed, please try again after some time.',
					{
						autoClose: true,
						closeButton: true,
					}
				);
			});
	}

	activateApp(app) {
		app.activating = true;
		apiClient('externalapps/activate', {
			method: 'POST',
			body: {
				id: app.id,
			},
		})
			.then(({ data, response }) => {
				if (!window.postAppActivation) {
					window.postAppActivation = this.completeActivation.bind(this);
				}
				// window.open(`http://${window.location.host}/auth-complete/1`,'Authenticate'+app.name, 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes')
				if (data.redirect) {
					window.open(
						data.redirect,
						'Authenticate' + app.name,
						'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
					);
				} else {
					this.completeActivation(app.id);
				}
			})
			.catch(({ response }) => {
				app.activationError = 'Error Occurred';
				console.error('activation error', response);
			});
	}

	openWebApp(app) {
		app.opening = true;
		window.location.href = `https://${window.APP_CONFIG.bublDomain}/service/v1/${app.id}/`;

		// apiClient(`externalapps/webapps/${app.id}`, {
		//   method: 'GET'
		// }).then(({data, response})=>{
		//   //${data.appDomain}
		//   let url = `https://${window.APP_CONFIG.bublDomain}/redirect?token=${data.tempToken}`
		//   var appWindow = window.open();
		//   //prevent access of window.opener https://mathiasbynens.github.io/rel-noopener/
		//   appWindow.opener = null;
		//   appWindow.location = url;
		//   app.opening = false
		// }).catch(({response})=>{
		//   app.opening = false
		//   toast.error('failed to fetch temporary token, please try again after some time.',  {
		//     autoClose: true,
		//     closeButton: true
		//   })
		//   console.error('app open error', response)
		// })
	}

	getEnvironment() {
		apiClient('environment', {
			method: 'GET',
		})
			.then(({ data, response }) => {
				runInAction(() => {
					this.env = data;
				});
				return apiClient('developerconfig', {
					method: 'GET',
				});
			})
			.then(({ data, response }) => {
				console.log('terminal', data);
				runInAction(() => {
					this.developerTerminalURL = data.developerURL;
				});
			})
			.catch(({ response }) => {
				console.error('terminal api error', response);
			});
	}
}
